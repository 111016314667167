import {getDictDataListByCodeApi} from "@/api/sys/dict";
import {getOptionSelectApi, getRoleListApi} from "@/api/sys/role"
import {getUserListApi} from "@/api/sys/user";

export default{
    namespaced:true,
    state:{
        deviceType:[], //设备类型列表
        userList:[],  //用户列表
        roleList: [],
        roleTree: [],
    },

    mutations:{
        setDeviceType(state, deviceType) {
            state.deviceType = deviceType;
        },
        setUserList: (state, userList) => {
            state.userList = userList
        },
        setRoleList: (state, roleList) => {
            state.roleList = roleList
        },
    },

    actions:{
        async asyncGetDeviceType({ commit },payload){
            let params = {
                dictType:'validate_device_type'
            };
            let res = await getDictDataListByCodeApi(params);
            if(!res)return;
            commit("setDeviceType", res.rows)
        },

        async getUserList({ commit },payload){
            let res = await getUserListApi();
            if(!res)return;
            commit("setUserList", res.rows)
        },

        async getRoleList({ commit },payload){
            let res = await getRoleListApi();
            if(!res)return;
            commit("setRoleList", res.rows)
        },


    }
}
