import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/styles/reset.scss";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {hasPermission} from '@/utils/directives';
Vue.use(ElementUI);

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

import '@/assets/icons/index'

Vue.config.productionTip = false
Vue.directive('permission', {
  inserted: hasPermission,
  updated: hasPermission,
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
