import request from '@/utils/request'

// 获取图片验证码
export function getCaptchaCodeApi() {
    return request({
        url: '/code',
        method: 'GET',
    })
}

// 登录页面租户下拉框
export function getEnantListApi() {
    return request({
        url: '/auth/tenant/list',
        method: 'GET',
    })
}

// 登录
export function loginApi(data) {
    return request({
        url: '/auth/login',
        method: 'POST',
        data: data
    })
}

// 短信登录
export function smsLoginApi(data) {
    return request({
        url: '/auth/smsLogin',
        method: 'GET',
        data: data
    })
}

// 邮箱登录
export function emailLoginApi(data) {
    return request({
        url: '/auth/emailLogin',
        method: 'GET',
        data: data
    })
}

// 获取用户信息
export function getUserInfoApi() {
    return request({
        url: '/system/user/getInfo',
        method: 'GET'
    })
}

// 获取用户可以访问的路由信息
export function getUserRoutersApi() {
    // return new Promise(resolve => {
    //     setTimeout(() => {
    //         resolve({success:'123'})
    //     },2000)
    // })
    return request({
        url: '/system/menu/getRouters',
        method: 'GET'
    })
}
//退出登录
export function logoutApi() {
    return request({
        url: '/auth/logout',
        method: 'POST'
    })
}

//注册
export function registerApi(data) {
    return request({
        url: '/auth/register',
        method: 'POST',
        data: data
    })
}
/**
 * ----------客户注册---------
* */

//查询客户注册列表
export function getCompanyApplyListApi(params) {
    return request({
        url: '/system/companyApply/list',
        method: 'GET',
        params
    })
}
//新增客户注册
export function addCompanyApplyApi(data) {
    return request({
        url: '/system/companyApply',
        method: 'POST',
        data: data
    })
}
//修改客户注册
export function editCompanyApplyApi( data) {
    return request({
        url: '/system/companyApply',
        method: 'PUT',
        data: data
    })
}
//获取客户注册详细信息
export function getCompanyApplyInfoApi(id) {
    return request({
        url: '/system/companyApply/' + id,
        method: 'GET',
    })
}
//删除客户注册
export function delectCompanyApplyApi(id) {
    return request({
        url: '/system/companyApply/'+ id,
        method: 'DELETE',
    })
}