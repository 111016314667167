import request from '@/utils/request'

//字典管理-- 字典类型 -- 查询字典类型列表
export function getDictTypeListApi(params) {
    return request({
        url: '/system/dict/type/list',
        method: 'GET',
        params
    })
}
//字典管理 -- 字典类型 -- 查询字典类型详细
export function getDictTypeInfoApi(id) {
    return request({
        url: '/system/dict/type/' + id,
        method: 'GET',
    })
}

//字典管理 -- 字典类型 -- 根据字典类型查询字典数据信息
export function getDictDataByTypeApi(dictType) {
    return request({
        url: '/system/dict/data/type/' + dictType,
        method: 'GET',
    })
}

//字典管理 -- 字典类型 -- 获取字典选择框列表
export function getDictTypeSelectApi() {
    return request({
        url: '/system/dict/type/optionselect',
        method: 'GET',
    })
}

//字典管理 -- 字典类型 -- 新增字典
export function addDictTypeApi(data) {
    return request({
        url: '/system/dict/type',
        method: 'POST',
        data: data
    })
}

//字典管理 -- 字典类型 -- 修改字典
export function editDictTypeApi( data) {
    return request({
        url: '/system/dict/type',
        method: 'PUT',
        data: data
    })
}

//字典管理 -- 字典类型 -- 删除字典
export function delectDictTypeApi(id) {
    return request({
        url: '/system/dict/type/'+ id,
        method: 'DELETE',
    })
}

//字典管理 -- 字典类型 -- 刷新字典缓存
export function delectDictTypeRefreshCacheApi() {
    return request({
        url: '/system/dict/type/refreshCache',
        method: 'DELETE',
    })
}

/**
 *----------------------------------------------------------------------
 * 字典管理-- 字典数据
 * **/
//字典管理-- 字典数据 -- 查询字典数据列表
export function getDictDataListByCodeApi(params) {
    return request({
        url: '/system/dict/data/list',
        method: 'GET',
        params
    })
}

//字典管理-- 字典数据 -- 查询字典数据详细
export function getDictDataByCodeApi(dictCode) {
    return request({
        url: '/system/dict/data/'+ dictCode,
        method: 'GET',
    })
}


//字典管理 -- 字典类型 -- 新增字典数据
export function addDictDataApi(data) {
    return request({
        url: '/system/dict/data',
        method: 'POST',
        data: data
    })
}

//字典管理 -- 字典类型 -- 修改字典数据
export function editDictDataApi( data) {
    return request({
        url: '/system/dict/data',
        method: 'PUT',
        data: data
    })
}

//字典管理 -- 字典类型 -- 删除字典类型
export function delectDictDataApi(id) {
    return request({
        url: '/system/dict/data/'+ id,
        method: 'DELETE',
    })
}