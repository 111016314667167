import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './modules/userInfo'
import userMenuData from './modules/userMenuData'
import base from './modules/base'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        userInfo,
        userMenuData,
        base,
    },
    getters
})
