
export const checkPermissions = (needPermissions, userPermissions) => {
     const needPerm = needPermissions || [];
     const userPerm = userPermissions || [];
     const universalPermissions= "*:*:*";
     if(userPerm.includes(universalPermissions)) {
          return true
     }
    needPerm.forEach(item => {
        if(!userPerm.includes(item)) {
            return false
        }
    })
    return true
}