<template>
  <el-container class="layout">

    <el-header>
      <HeadNav></HeadNav>
    </el-header>

    <el-container class="container">
      <!-- 侧边栏 -->
      <LeftMenu></LeftMenu>

      <!-- 主页面 -->
      <el-main class="bg_white">
        <transition name="fade-transform" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>

    </el-container>
  </el-container>
</template>


<script>
import HeadNav from "./components/HeadNav"
import LeftMenu from "./components/LeftMenu"

export default {
  components: {
    HeadNav,
    LeftMenu,
  }
};
</script>

<style lang="scss" scoped>
.layout{
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.el-header{
  height: 60px !important;
  padding: 0;
}
.el-main{
  height: calc(100vh - 72px);
  margin: 8px;
  border-radius: 5px;
  padding: 0 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

/* 进场的开始效果 和 离开场的结束效果  */
.fade-transform-enter, .fade-transform-leave-to{
  opacity: 0;
  transform: translateX(10px);
}

/* 指定要过渡的属性 */
.fade-transform-enter-active,.fade-transform-leave-active{
  transition:all .3s;
}

/* 进场的结束效果  和 离开场的开始效果  */
.fade-transform-enter-to, .fade-transform-leave{
  opacity: 1;
  transform: translateX(0px);
}
</style>