<template>
  <div class="menu-item">
    <template v-for="(item, idx) in data">
      <!-- 因为有子集和无子集渲染html标签不一样，所以要分为两种情况 -->
      <!--情况一：有子集的情况： -->
      <el-submenu :key="idx" :index="item.path" v-if="item.children && item.children.length > 0">
        <template slot="title">
        <!-- <i :class="item.icon"></i> -->
          <svg-icon class="svg-icon" :icon-file-name="item.icon"></svg-icon>
          <span slot="title">{{ item.title }}</span>
        </template>
        <Smenuitem :data="item.children"></Smenuitem>
      </el-submenu>

      <!-- 情况二：没子集的情况 -->
      <el-menu-item v-else  :key="idx"  :index="item.path">
        <svg-icon class="svg-icon" :icon-file-name="item.icon+'' "></svg-icon>
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    Smenuitem: () => import('./menuItem')
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  update() {
    console.log(this.props, 999999)
  }
}
</script>

<style lang="scss" scoped>
.menu-item{
  ::v-deep  .menu-item{
    width:  calc(100% - 10px);
    margin-left: 10px;
    overflow: hidden;
  }
  .svg-icon{
    margin: 0 6px 0 4px;
  }
}
</style>