export const hasPermission = (el, binding, vnode) => {
    const universalPermissions= "*:*:*";
    // 假设我们从Vuex或者Pinia获取到当前用户的所有权限
    let permissions = vnode.context.$store.getters.permissions; // 或者使用pinia: context.$store.permissions

    // 获取按钮需要的权限名称（binding.value）
    const requiredPermission = binding.value;
    //console.log('requiredPermission',permissions, requiredPermission);
    // 判断用户是否有该权限
    if(permissions.includes(universalPermissions)) {
        return true
    }
    if (permissions.includes(requiredPermission[0])) {
        el.style.display = null; // 显示按钮
    } else {
        el.style.display = 'none'; // 隐藏按钮
    }
};