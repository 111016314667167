import request from '@/utils/request'

//用户管理 -- 获取用户列表
export function getUserListApi(params) {
    return request({
        url: '/system/user/list',
        method: 'GET',
        params
    })
}

//用户管理 -- 新增用户
export function addUserApi(data) {
    return request({
        url: '/system/user',
        method: 'POST',
        data: data
    })
}

//用户管理 -- 修改用户
export function editUserApi( data) {
    return request({
        url: '/system/user',
        method: 'PUT',
        data: data
    })
}

//用户管理 -- 根据用户编号获取详细信息
export function getUserInfoApi(id) {
    return request({
        url: '/system/user/' + id,
        method: 'GET',
    })
}

//用户管理 -- 获取部门树列表
export function getUserDeptTreeApi() {
    return request({
        url: '/system/user/deptTree',
        method: 'GET',
    })
}


//用户管理 -- 删除用户
export function delectUserApi(id) {
    return request({
        url: '/system/user/'+ id,
        method: 'DELETE',
    })
}

//用户管理 -- 用户授权角色
export function authRole( data) {
    return request({
        url: '/system/user/authRole',
        method: 'PUT',
        data: data
    })
}

//用户管理 -- 用户状态修改
export function changeStatusApi( data) {
    return request({
        url: '/system/user/changeStatus',
        method: 'PUT',
        data: data
    })
}

/*-------------------个人中心-个人信息---------------------*/
//个人信息
export function getProfileInfoApi() {
    return request({
        url: '/system/user/profile',
        method: 'GET',
    })
}
//修改用户信息
export function editProfileInfoApi( data) {
    return request({
        url: '/system/user/profile',
        method: 'PUT',
        data: data
    })
}

//重置密码
export function updatePwdApi( data,configs) {
    return request({
        url: '/system/user/profile/updatePwd',
        method: 'PUT',
        data: data,
        ...configs,
    })
}
//头像上传
export function uploadAvatarApi(data,configs) {
    return request({
        url: '/system/user/profile/avatar',
        method: 'POST',
        data: data,
       ...configs,
    })
}