import request from '@/utils/request'

//角色管理 -- 获取角色列表
export function getRoleListApi(params) {
    return request({
        url: '/system/role/list',
        method: 'GET',
        params
    })
}

//角色管理 -- 新增角色
export function addRoleApi(data) {
    return request({
        url: '/system/role',
        method: 'POST',
        data: data
    })
}

//角色管理 -- 修改角色
export function editRoleApi( data) {
    return request({
        url: '/system/role',
        method: 'PUT',
        data: data
    })
}

//角色管理 -- 根据角色编号获取详细信息
export function getRoleInfoApi(id) {
    return request({
        url: '/system/role/' + id,
        method: 'GET',
    })
}

//角色管理 -- 获取角色选择框列表
export function getOptionSelectApi() {
    return request({
        url: '/system/role/optionselect',
        method: 'GET',
    })
}

//角色管理 -- 加载对应角色菜单列表树
export function getRoleMenuTreeselectApi(id) {
    return request({
        url: '/system/menu/roleMenuTreeselect/'+ id,
        method: 'GET',
    })
}


//角色管理 -- 删除角色
export function delectRoleApi(id) {
    return request({
        url: '/system/role/'+ id,
        method: 'DELETE',
    })
}

//角色管理--导出角色信息列表
export function roleExportApi(data) {
    return request({
        url: '/system/role/export',
        method: 'POST',
        data: data
    })
}