import axios from 'axios'
import { Message } from 'element-ui'
import router from "@/router";

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API || 'api', // api 的 base_url
    timeout: 240000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
    const token = localStorage.getItem("authorization-token")
    if(token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage") ){
        config.headers["Authorization"] =token
    }
   // console.log('config.headers', config)
    config.headers['Content-Type'] =  config.headers['Content-Type'] || 'application/json; charset=UTF-8'
    return config

}, error => {
    console.log(error) // for debug
   return Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(response => {
        /**
         * code为非200是抛错
         */
        const res = response.data
        if(res.code && res.code!=200){
            Message.error(res.msg || '网络请求错误');
            // 只要不是200，都会走这里
            if(res.code == 401){
                // 401:未登录--token过期或者没带;
                localStorage.removeItem("authorization-token");
                location.reload()
                router.push("/login")
            }

            return false
            //return Promise.reject(res.msg || res.message || 'error code not 200')
        }

        return response.data

    },error => {
        console.log('err' + error)// for debug
        Message({message: error.message, type: 'error', duration: 3 * 1000})
        return Promise.reject(error)
    }
);

export default service
