const getters = {
  avatar: state => state.userInfo.userInfo.user.avatar,
  nickName: state => state.userInfo.userInfo.user.nickName,
  userName:state => state.userInfo.userInfo.user.userName,
  roles: state => state.userInfo.userInfo.user.roles,
  userId: state => state.userInfo.userInfo.user.userId,
  roleList: state => state.roleList,
  roleListCount: state => state.roleList.length,
  permissions: state => state.userInfo.userInfo.permissions,
  deviceType:state => state.base.deviceType,
}
export default getters
