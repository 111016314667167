<template>
  <el-aside width="collapse">

    <el-menu
        class="el-menu-vertical-demo"
        router
        :default-active="this.$route.path"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        unique-opened
        :collapse-transition = "true"
        active-text-color='#1890ff'
        background-color="#072f52"
        text-color="#fff"
    >
      <Smenuitem :data="menuData"></Smenuitem>
    </el-menu>

    <div class="collapse-btn" @click="changeIsCollapse()">
      <i :class="isCollapse ? 'el-icon-s-unfold': 'el-icon-s-fold'"></i>
    </div>

  </el-aside>

</template>

<script>
import { mapState } from 'vuex'
import Smenuitem  from './menuItem'


export default {
  components: { Smenuitem},
  data(){
    return{
      isCollapse: false, //侧边栏是否展开收起
      //menuData:[],
    }
  },
  computed:{
      ...mapState({
        menuData: state => state.userMenuData.menuData
      })
  },

  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    //侧边栏展开收起
    changeIsCollapse() {
      this.isCollapse = !this.isCollapse;
    }
  }
}
</script>

<style lang="scss" scoped>
.el-aside {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 60px) ;
  display:flex;
  transition: width 0.15s;
  -webkit-transition: width 0.15s;
  -moz-transition: width 0.15s;
  -webkit-transition: width 0.15s;
  -o-transition: width 0.15s;
  .el-menu{
    border: none;
    border-right-width: 0;
  }
  .collapse-btn{
    text-align: center;
    font-weight: bold;
    background-color: #f0f2f5;
    cursor: pointer;
    height: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
}
.el-aside::-webkit-scrollbar {
  width: 0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
::v-deep .el-menu--collapse  .el-submenu__title span{
  display: none;
}
::v-deep .el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
  display: none;
}
::v-deep .el-submenu .el-menu-item{
  padding: 0 0 0 30px!important;
}
</style>

