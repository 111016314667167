import { getUserInfoApi } from "@/api/account"

export default{
    namespaced:true,
    state:{
        // 用户信息 做 localStorage本地存储(刷新会丢失，所以存本地)
        userInfo:JSON.parse(localStorage.getItem("userInfo")) || {
            permissions:null,
            roles:null,
            user:null
        }
    },
    mutations:{
        changeUserInfo(state,payload){
            state.userInfo = payload;
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
        },
    },
    actions:{
        async asyncChangeUserInfo({ commit },payload){
            let getUserInfoApiRes = await getUserInfoApi();
            //console.log(getUserInfoApiRes);
            if(!getUserInfoApiRes)return;
            commit("changeUserInfo",{
                permissions:getUserInfoApiRes.data.permissions,
                roles:getUserInfoApiRes.data.roles,
                user:getUserInfoApiRes.data.user
            })
        },
    }
}
